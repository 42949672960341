.card {
  width: 55ch;
  height: 45ch;
  background: grey;
  border-radius: 5px;
  background-size: cover;
  background-position: center center;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.5s;
  will-change: transform;
  border: 15px solid white;
}

.west__seattle {
  background-image: url("./assets/west-seattle.jpg");
}

.battleship {
  background-image: url("./assets/battleship.png");
}

.zine__maker {
  background-image: url("./assets/zine-maker.png");
}

.nurse__scheduler {
  background-image: url("./assets/nurse-scheduler.jpg");
}

.card:hover {
  box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);
}
